<template>
  <v-container class="my-6 py-6">
    <crear-distribuidor />
    <empleados />
  </v-container>
</template>

<script>
export default {
  components: {
    crearDistribuidor: () => import('./CrearDistribuidor'),
    empleados: () => import('./Empleados')
  }
}
</script>

<style>

</style>